import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import NavigationBlog from "../components/navigationBlog"
import { Container } from "react-bootstrap"
import StoryCard from "../components/blog/storyCard"
import Buttons from "./../components/common/button"
import SuggestedStories from "../components/blog/landingSuggestedStories"
import Frame from "../components/common/frame"

function BlogCategory({ pageContext }) {
  const { tag } = pageContext

  const [itemsToBeShown, setItemsToBeShown] = useState(12)

  const [blogListCount, setBlogListCount] = useState(tag.posts.length)

  var currentItem = 0

  // const [param, setParam] = useState("")

  // const handleInputChange = event => {
  //   setParam(event.target.value)
  // }

  // const handleInputSubmit = event => {
  //   if (event.key === "Enter") {
  //     window.location.href = `/blog/search/${param}`
  //   }
  // }

  // const handleButtonSubmit = () => {
  //   window.location.href = `/blog/search/${param}`
  // }

  return (
    <div className="blog">
      <StaticQuery
        query={graphql`
          query contentCategory {
            SuperOps {
              navigationContents(where: { section: "Library" }) {
                section
                isParentOnly
                parentTitle
                parentDescription
                parentTagBg
                childTitle
                childSlug
                childDescription
                childIcon {
                  url
                }
                parentTagSecondaryBg
                parentSlug
              }
              pages(where: { title: "Blog" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { navigationBlock } = page
              return (
                <>
                  <Frame
                    seo={tag.seo}
                    ogType="blog"
                  >
                    <header>
                      <NavigationBlog
                        links={navigationBlock}
                        background="#fff2f1"
                        libraryLinks={data.SuperOps.navigationContents}
                      />
                    </header>
                    <section className="main">
                      <Container className="title">
                        <h3>{tag.name}</h3>
                      </Container>
                      <Container className="shadowbg" />
                      <Container className="content">
                        <div className="grid-container">
                          {tag.posts.map(post => {
                            const {
                              title,
                              excerpt,
                              author,
                              coverImage,
                              tags,
                              readTime,
                              slug,
                              isBlog,
                            } = post

                            return (
                              <div
                                className="grid-lg-3 grid-md-2 grid-sm-1"
                                style={{
                                  display:
                                    currentItem >= itemsToBeShown && "none",
                                }}
                              >
                                <span className="dspnone">{currentItem++}</span>
                                <StoryCard
                                  title={title}
                                  excerpt={excerpt}
                                  author={author}
                                  coverImage={coverImage}
                                  tags={tags}
                                  readTime={readTime}
                                  slug={slug}
                                  contentEllipseLine="3"
                                  cardSize="small"
                                  contentMinHeight="368px"
                                  isBlog={isBlog}
                                  style={{ marginBottom: "48px" }}
                                />
                              </div>
                            )
                          })}
                        </div>

                        {blogListCount <= 12 ||
                        itemsToBeShown >= blogListCount ? null : (
                          <div className="blog-view-more">
                            <Buttons
                              theme="primary"
                              text="VIEW MORE STORIES"
                              onClick={() =>
                                setItemsToBeShown(itemsToBeShown + 6)
                              }
                            />
                          </div>
                        )}

                        <span className="p16 section-title">
                          <p>SUGGESTED STORIES</p>
                        </span>

                        <SuggestedStories tag={tag.name} />
                      </Container>
                    </section>
                  </Frame>
                </>
              )
            })}
          </>
        )}
      />
    </div>
  )
}

export default BlogCategory
