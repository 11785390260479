import React from "react"
import { Link,StaticQuery, graphql } from "gatsby"

const featuredTags = (props) => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query featuredTags {
            SuperOps {
              tags(where: { isFeatured: Yes }) {
                name
                slug
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.tags.map(tag => {
              const { name, slug } = tag
              return (props.mobile ? (<Link
                  to={`/${slug}`}
                  className="tdnone"
                >
                  <div className="feat-drp-child categories-drp-child">
                    <h6> {name.toUpperCase()} </h6>
                  </div>
                </Link>) : (<span className="a13">
                  <Link to={`/${slug}`}>{name.toUpperCase()}</Link>
                </span>)
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default featuredTags
