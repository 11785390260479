import React from "react"
import { Col, Row } from "react-bootstrap"
import Arrow from "../../assets/images/nav-arrow.svg"

const navLibraryDropdownMob = props => {
  return (
    <>
      <Row>
        {props.items.map((dp, i) => {
          const { childTitle, childSlug, childDescription, section } = dp
          return section === "Library" ? (
            <Col lg={3}>
              <div className="feat-drp-strip tnone">
                {childTitle.map((title, index) => {
                  return (
                    <a href={childSlug[index]}>
                      <div className="feat-drp-only-parent lib-drp-parent">
                        <img src={Arrow} alt="img" />
                        <h5> {title} </h5>
                        <p> {childDescription[index]} </p>
                      </div>
                    </a>
                  )
                })}
              </div>
            </Col>
          ) : null
        })}
      </Row>
    </>
  )
}

export default navLibraryDropdownMob
